import React, { useState, useRef } from 'react';
import { Form, Button, Row, Col, Container, Card, Image, CloseButton } from 'react-bootstrap';
import { toast } from 'react-toastify'; // Add this import
import ApiRequest from '../../Utils/AxiosHelper'; // Add this import

const CreateSessionForm = () => {
  const [formData, setFormData] = useState({
    title: '',                  // Updated field name
    monthlyFee: 0,
    startDate: '',
    endDate: '',
    registrationDeadline: '',
    description: '',            // Updated field name
    coverImage: null,          // Updated field name
  });
  const [imagePreview, setImagePreview] = useState(null);
  const fileInputRef = useRef(null);  // Reference for the file input

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData({
        ...formData,
        coverImage: file,  // Updated field name
      });
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      clearImageSelection();
    }
  };

  const clearImageSelection = () => {
    setFormData({ ...formData, coverImage: null });  // Updated field name
    setImagePreview(null);
    fileInputRef.current.value = '';  // Reset the file input
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const response = await ApiRequest(
        'POST',
        '/admin/Session',
        formData,
        { 'Content-Type': 'multipart/form-data' },
        {},
        true
      );

      console.log(response,response.success);
      if (response.status === 201) {
        toast.success('Session created successfully!');
        // Reset form data to initial state
        setFormData({
          title: '',
          monthlyFee: 0,
          startDate: '',
          endDate: '',
          registrationDeadline: '',
          description: '',
          coverImage: null,
        });
        setImagePreview(null);
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      } else {
        toast.error(response.message || 'Failed to create session');
      }
    } catch (error) {
      toast.error('An unexpected error occurred');
      console.error('Error creating session:', error);
    }
  };

  return (
    <Container className="my-5">
      <Row className="justify-content-center">
        <Col md={8}>
          <Card className="p-4 shadow">
            <Card.Body>
              <h3 className="text-center mb-4">Create New Session</h3>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="title">
                  <Form.Label>Session Title</Form.Label>
                  <Form.Control
                    type="text"
                    name="title"             // Updated field name
                    value={formData.title}
                    onChange={handleInputChange}
                    placeholder="Enter session title"
                    required
                  />
                </Form.Group>

                <Form.Group controlId="monthlyFee" className="mt-3">
                  <Form.Label>Monthly Tuition Fee</Form.Label>
                  <Form.Control
                    type="number"
                    name="monthlyFee"        // Updated field name
                    value={formData.monthlyFee}
                    onChange={handleInputChange}
                    placeholder="Enter monthly fee"
                  />
                </Form.Group>

                <Form.Group controlId="startDate" className="mt-3">
                  <Form.Label>Start Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="startDate"
                    value={formData.startDate}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>

                <Form.Group controlId="endDate" className="mt-3">
                  <Form.Label>End Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="endDate"
                    value={formData.endDate}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>

                <Form.Group controlId="registrationDeadline" className="mt-3">
                  <Form.Label>Registration Deadline</Form.Label>
                  <Form.Control
                    type="date"
                    name="registrationDeadline"  // Updated field name
                    value={formData.registrationDeadline}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>

                <Form.Group controlId="description" className="mt-3">
                  <Form.Label>Session Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="description"          // Updated field name
                    rows={3}
                    value={formData.description}
                    onChange={handleInputChange}
                    placeholder="Enter a brief description of the session"
                  />
                </Form.Group>

                <Form.Group controlId="coverImage" className="mt-4">
                  <Form.Label>Session Cover Image</Form.Label>
                  <Form.Control
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    ref={fileInputRef}  // Reference to access the input element
                  />
                </Form.Group>

                {imagePreview && (
                  <div className="mt-3 position-relative text-center">
                    <CloseButton
                      className="position-absolute top-0 end-0"
                      onClick={clearImageSelection}
                    />
                    <Image
                      src={imagePreview}
                      alt="Session Cover"
                      fluid
                      rounded
                      style={{ maxHeight: '300px' }}
                    />
                  </div>
                )}

                <Button variant="primary" type="submit" className="mt-4 w-100">
                  Create Session
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default CreateSessionForm;
