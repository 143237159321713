import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Button, Container, Row, Col, Image } from 'react-bootstrap';
import { useLocation, Link } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import ApiRequest from '../../Utils/AxiosHelper';
import './SessionInfo.css';

const SessionInfo = () => {
  const [activeTab, setActiveTab] = useState('lectures');
  const [lectures, setLectures] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const limit = 10;

  const location = useLocation();
  const sessionData = location.state?.sessionData;

  const fetchLectures = async (pageNum = page) => {
    try {
      setLoading(true);
      const response = await ApiRequest(
        'GET',
        '/admin/lectures',
        null,
        {},
        {
          sessionId: sessionData?._id,
          limit,
          page: pageNum
        },
        true
      );

      if (response.data) {
        const newLectures = response.data.lectures;
        if (pageNum === 1) {
          setLectures(newLectures);
        } else {
          setLectures(prev => [...prev, ...newLectures]);
        }
        
        // Update hasMore based on totalPages
        setHasMore(response.data.currentPage < response.data.totalPages);
      } else {
        console.error('Error fetching lectures:', response.message);
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  const loadMore = () => {
    setPage(prev => prev + 1);
    fetchLectures(page + 1);
  };

  const getYoutubeEmbedUrl = (url) => {
    try {
      // Handle different YouTube URL formats
      if (url.includes('youtu.be')) {
        // Handle shortened URLs
        const videoId = url.split('youtu.be/')[1];
        return `https://www.youtube.com/embed/${videoId}`;
      } else if (url.includes('youtube.com')) {
        // Handle regular URLs
        const videoId = url.split('v=')[1]?.split('&')[0];
        if (!videoId) throw new Error('Could not extract video ID');
        return `https://www.youtube.com/embed/${videoId}`;
      }
      return url;
    } catch (error) {
      console.error('Error parsing YouTube URL:', error, 'URL:', url);
      return url;
    }
  };

  // const handleVideoClick = (lectureId) => {
  //   console.log('Video clicked:', lectureId);
  //   console.log('Video URL:', lectures.find(l => l._id === lectureId)?.lectureLink);
  //   setActiveVideos(prev => ({
  //     ...prev,
  //     [lectureId]: !prev[lectureId]
  //   }));
  // };

  useEffect(() => {
    if (sessionData?._id) {
      setPage(1);
      fetchLectures(1);
    }
  }, [sessionData?._id]);

  return (
    <Container className="session-info-container my-5">
      <Row className="mb-4">
        <Col md={4}>
          <Image 
            src={sessionData?.coverImage} 
            alt="Session Cover" 
            className="w-100 mb-3"
            onError={(e) => {
              e.target.src = 'fallback-image-url.jpg';
              e.target.onerror = null;
            }}
          />
        </Col>
        <Col md={8}>
          <h2>{sessionData?.title}</h2>
          <p className="text-muted">{sessionData?.description}</p>
          <div className="session-details">
            <p><strong>Monthly Fee:</strong> Rs. {sessionData?.monthlyFee}</p>
            <p><strong>Start Date:</strong> {new Date(sessionData?.startDate).toLocaleDateString()}</p>
            <p><strong>End Date:</strong> {new Date(sessionData?.endDate).toLocaleDateString()}</p>
            <p><strong>Registration Deadline:</strong> {new Date(sessionData?.registrationDeadline).toLocaleDateString()}</p>
          </div>
        </Col>
      </Row>
      
      <Row className="justify-content-center">
        <Col md={8}>
          <Tabs
            activeKey={activeTab}
            onSelect={(k) => setActiveTab(k)}
            className="justify-content-center session-tabs"
            variant="pills"
            id="session-tabs"
          >
            <Tab eventKey="lectures" title="Lectures">
              <div className="tab-content">
                <Row className="mb-3">
                  <Col className="d-flex justify-content-end">
                    <Link 
                      to="/upload-lecture" 
                      state={{ sessionId: sessionData?._id }}
                    >
                      <Button variant="primary" className="add-button">
                        + Add New Lecture
                      </Button>
                    </Link>
                  </Col>
                </Row>

                <InfiniteScroll
                  dataLength={lectures.length}
                  next={loadMore}
                  hasMore={hasMore}
                  loader={
                    <div className="text-center my-3">
                      <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  }
                  endMessage={
                    <p className="text-center text-muted my-3">
                      No more lectures to load.
                    </p>
                  }
                >
                  <ul className="lecture-list">
                    {lectures.map((lecture) => (
                      <li key={lecture._id} className="lecture-item">
                        <div className="lecture-video-container">
                          {lecture.isYoutubeVideo ? (
                            <>
                              {console.log('Original URL:', lecture.lectureLink)}
                              {console.log('Transformed URL:', getYoutubeEmbedUrl(lecture.lectureLink))}
                              <iframe
                                className="lecture-video"
                                src={getYoutubeEmbedUrl(lecture.lectureLink)}
                                title={lecture.title}
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                onError={(e) => console.error('iframe error:', e)}
                                loading="lazy"
                              />
                            </>
                          ) : lecture.lectureLink ? (
                            <video 
                              className="lecture-video"
                              controls
                              preload="metadata"
                              key={lecture.lectureLink}
                              onError={(e) => {
                                console.error('Video error:', e);
                                console.log('Failed video URL:', lecture.lectureLink);
                              }}
                              onLoadStart={() => console.log('Video load started:', lecture.lectureLink)}
                            >
                              <source 
                                src={lecture.lectureLink} 
                                type="video/mp4"
                                onError={(e) => console.error('Source error:', e)}
                              />
                              Your browser does not support the video tag.
                            </video>
                          ) : (
                            <div className="video-placeholder">
                              <div className="play-button">
                                <i className="fas fa-play"></i>
                              </div>
                              <div className="lecture-title-overlay">{lecture.title}</div>
                            </div>
                          )}
                        </div>
                        <div className="lecture-info">
                          <div className="lecture-title">{lecture.title}</div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </InfiniteScroll>

                {lectures.length === 0 && !loading && (
                  <div className="text-center text-muted">
                    No lectures available
                  </div>
                )}
              </div>
            </Tab>

            <Tab eventKey="notes" title="Notes" disabled>
              <div className="tab-content">
                {/* Notes content removed */}
              </div>
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </Container>
  );
};

export default SessionInfo;
