import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Login from './Components/Login';
import Navbar from './Components/Navbar';
import VideoUpload from './Components/FileUploaderContainer';
import CreateSessionForm from './Components/Session/CreateSessionForm';
import HomeScreen from './Components/Session/Home';
import SessionInfo from './Components/Session/SessionInfo';
import {getUser} from './Utils/CookiesHelper.js';

// New component for protected routes
const ProtectedRoute = ({ children }) => {
  const isAuthenticated = getUser();
  return isAuthenticated ? children : <Navigate to="/" replace />;
};

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path='/upload-lecture' element={
          <ProtectedRoute>
            <VideoUpload />
          </ProtectedRoute>
        } />
        <Route path='/Create-session' element={
          <ProtectedRoute>
            <CreateSessionForm />
          </ProtectedRoute>
        } />
        <Route path='/sessions' element={
          <ProtectedRoute>
            <HomeScreen />
          </ProtectedRoute>
        } />
        <Route path='/sessionInfo/:id' element={
          <ProtectedRoute>
            <SessionInfo />
          </ProtectedRoute>
        } />
      </Routes>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Router>
  );
}

export default App;
