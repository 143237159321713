import React, { useEffect, useState } from 'react';
import './SessionDropDown.css';
import ApiRequest from '../../Utils/AxiosHelper';

const SessionDropdown = ({ value, onChange }) => {
  const [sessions, setSessions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSessions = async () => {
      try {
        const queryParams = {
          status: "Active",
        };
  
        const response = await ApiRequest(
          'GET',
          '/admin/sessions',
          null,
          {},
          queryParams,
          true
        );
  
        //const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/admin/sessions?status=Active`);
        setSessions(response.data.sessions);
      } catch (err) {
        setError('Failed to fetch sessions');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchSessions();
  }, []);

  const handleChange = (event) => {
    onChange(event.target.value);
  };

  if (loading) return <p className="session-dropdown-loading">Loading sessions...</p>;
  if (error) return <p className="session-dropdown-error">{error}</p>;

  return (
    <div className="session-dropdown-container">
      <select 
        id="sessionDropdown" 
        onChange={handleChange} 
        value={value}
        className="session-dropdown-select"
      >
        <option value="">--Select a session--</option>
        {sessions.length > 0 && sessions.map((session) => (
          <option key={session._id} value={session._id}>
            {session.title}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SessionDropdown;