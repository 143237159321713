import React, { useEffect, useState, useCallback } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap"; // Adjust imports based on your setup
import { MDBInput } from "mdb-react-ui-kit";
//import { Link } from "react-router-dom";
import "./index.css"; // Import the new CSS file
import ApiRequest from "../../Utils/AxiosHelper";

const VerificationModal = ({
  modalData,
  setModalData,
  handleModalChange,
  handleVerificationSubmit,
}) => {
  const [timeLeft, setTimeLeft] = useState(200); // 1-minute timer
  const [resendMessage, setResendMessage] = useState('');
  const [resendMessageTimer, setResendMessageTimer] = useState(null);

  useEffect(() => {
    if (modalData.modalOpen) {
      setTimeLeft(200); // Reset timer when the modal opens
    }
  }, [modalData.modalOpen]);

  useEffect(() => {
    let timer;
    if (timeLeft > 0) {
      timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
    }
    return () => clearTimeout(timer); 
  }, [timeLeft]);

  const clearErrorMessage = useCallback(() => {
    setModalData(prevData => ({ ...prevData, errorMessage: null }));
  }, [setModalData]);

  useEffect(() => {
    const errorTimer = setTimeout(clearErrorMessage, 3000);
    return () => clearTimeout(errorTimer);
  }, [modalData.errorMessage, clearErrorMessage]);

  useEffect(() => {
    if (resendMessage && !resendMessageTimer) {
      const timer = setTimeout(() => {
        setResendMessage('');
        setResendMessageTimer(null);
      }, 3000);
      setResendMessageTimer(timer);
    }
    return () => {
      if (resendMessageTimer) {
        clearTimeout(resendMessageTimer);
      }
    };
  }, [resendMessage, resendMessageTimer]);

  const handleResendVerificationCode = useCallback(async () => {
    if (timeLeft > 0) return;

    try {
      setModalData((prevData) => ({ ...prevData, verifying: true }));

      const response = await ApiRequest('POST','/resendCode',{cnicOrEmail:modalData.cnicOrEmail},{},{})
      if (response.status === 200) {
        console.log("Verification code resent");
        setTimeLeft(200);
        setResendMessage(response.data.message);
      }
    } catch (error) {
      console.error("Failed to resend verification code", error);
      setResendMessage("Failed to resend verification code");
    } finally {
      setModalData((prevData) => ({ ...prevData, verifying: false }));
    }
  }, [timeLeft, modalData.cnicOrEmail, setModalData]);

  return (
    <Modal isOpen={modalData.modalOpen} className="verification-modal">
      <ModalHeader className="verification-modal-header">Verification Required</ModalHeader>
      <ModalBody className="verification-modal-body">
        <form onSubmit={(e) => {
          e.preventDefault();
          handleVerificationSubmit();
        }}>
          <p className="verification-message">{modalData.modalDataMessage}</p>
          <MDBInput
            type="number"
            name="verificationCode"
            className="verification-input mb-3"
            placeholder="Enter Verification Code"
            value={modalData.verificationCode}
            onChange={(e) => handleModalChange(e)}
            required
          />
          {modalData.errorMessage && (
            <div className="message-container error">
              <span className="message-text">{modalData.errorMessage}</span>
            </div>
          )}
          {resendMessage && (
            <div className="message-container success">
              <span className="message-text">{resendMessage}</span>
            </div>
          )}
          <div className="timer-container">
            <p className={`timer ${timeLeft === 0 ? 'expired' : ''}`}>
              {timeLeft > 0
                ? `Code will expire in ${timeLeft} seconds`
                : "Code has Expired"}
            </p>
            <Button
              color="link"
              className={`resend-link ${timeLeft > 0 ? "disabled" : ""}`}
              onClick={handleResendVerificationCode}
              disabled={timeLeft > 0}
            >
              Resend verification code
            </Button>
          </div>
        </form>
      </ModalBody>
      <ModalFooter className="verification-modal-footer">
        <Button
          color="primary"
          className="submit-button"
          onClick={handleVerificationSubmit}
          disabled={modalData.verifying || modalData.verificationCode.length !== 6 || timeLeft === 0}
        >
          {modalData.verifying ? "Verifying..." : "Submit Code"}
        </Button>
        <Button
          color="secondary"
          className="cancel-button"
          onClick={() => setModalData((prevData) => ({ ...prevData, modalOpen: false }))}
        >
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default VerificationModal;
