import CryptoJS from 'crypto-js';

const encryptData = (data, secretKey) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
};

const decryptData = (encryptedData, secretKey) => {
  const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
  const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
  return decryptedData ? JSON.parse(decryptedData) : null;
};

const getExpirationTime = (hours) => {
  const now = new Date();
  now.setHours(now.getHours() + hours);
  return now.toISOString(); // Return ISO string to store as expiration time
};

export const storeCookies = (key, data, needEncryption = false, expirationInHours = 23) => {
  let storedData = data;
  const secretKey = process.env.REACT_APP_ENCRYPTION_KEY;

  if (needEncryption) {
    storedData = encryptData(data, secretKey);
  }

  const expirationTime = getExpirationTime(expirationInHours); // Get expiration time (22 hours from now)

  // Store data with expiration time and encryption flag
  localStorage.setItem(key, JSON.stringify({
    data: storedData,
    encrypted: needEncryption,
    expiration: expirationTime
  }));
};

export const getCookie = (key) => {
  const cookie = localStorage.getItem(key);

  if (!cookie) return null;

  const parsedCookie = JSON.parse(cookie);
  const currentTime = new Date().toISOString(); // Current time

  console.log('par',parsedCookie);

  // Check if the cookie is expired
  if (parsedCookie.expiration && currentTime > parsedCookie.expiration) {
    removeCookie(key); // Remove expired cookie
    return null;
  }

  const secretKey = process.env.REACT_APP_ENCRYPTION_KEY;

  if (parsedCookie.encrypted) {
    return decryptData(parsedCookie.data, secretKey);
  }

  return parsedCookie.data;
};

export const removeAllCookies = () => {
  localStorage.clear();
};

export const removeCookie = (key) => {
  localStorage.removeItem(key);
};

export const getUser = () => getCookie('user');
export const getAuthToken = () => getCookie('authToken');