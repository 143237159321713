import React, { useState, useCallback } from 'react';
import VideoUploader from '../VideoUploader';
import './index.css';

const FileUploaderContainer = () => {
  const [uploaders, setUploaders] = useState([{ id: Date.now() }]);

  const addNewUploader = () => {
    setUploaders(prev => [...prev, { id: Date.now() }]);
  };

  const removeUploader = (id) => {
    setUploaders(prev => {
      const filtered = prev.filter(uploader => uploader.id !== id);
      if (filtered.length === 0) {
        return [{ id: Date.now() }];
      }
      return filtered;
    });
  };

  const handleUploadComplete = useCallback((uploaderId) => {
    setTimeout(() => {
      removeUploader(uploaderId);
    }, 3000);
  }, []);

  return (
    <div className="multi-file-upload-container">
      <h1 className="multi-file-upload-header">Upload Multiple Lectures</h1>
      
      {uploaders.map((uploader,index) => (
        <div key={uploader.id} className="file-upload-wrapper">
          <VideoUploader 
            uploaderId={uploader.id}
            onRemove={() => removeUploader(uploader.id)}
            onUploadComplete={handleUploadComplete}
            index = {index}
          />
        </div>
      ))}

      <button 
        onClick={addNewUploader}
        className="add-file-button btn btn-primary"
      >
        Add Another Lecture
      </button>
    </div>
  );
};

export default FileUploaderContainer;
