import React, { useEffect, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa6";
// import { Logo } from "../../public/images/pace-gk-logo.png";
// import {Image} from "../../../public/images/Pace_GK-cover.jpg";
import Lottie from "react-lottie-player";
import LoginImage from "../../Utils/LoginImage.json"
import { useNavigate } from "react-router-dom";
import VerificationModal from "../VerificationModal";
import {getUser, storeCookies} from '../../Utils/CookiesHelper';
import ApiRequest from "../../Utils/AxiosHelper";
import "./index.css"

const Login = () => {
  const navigate = useNavigate();
  const isUserLoggedIn = getUser();
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    cnicOrEmail: "",
    password: "",
    loading: false,
  });

  const [modalData, setModalData] = useState({
    modalOpen: false,
    verificationCode: "",
    verifying: false,
    modalDataMessage: "",
    errorMessage: "",
    cnicOrEmail:"",
  });

  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (isUserLoggedIn) navigate("/sessions");
  }, [navigate, isUserLoggedIn]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setModalData((prevData) => ({ ...prevData, errorMessage: "" }));
  };

  const handleLogin = async (e) => {
    console.log("in handle login");
    e.preventDefault();
    setFormData((prevData) => ({ ...prevData, loading: true }));
    setErrorMessage("");

    try {
      const response = await ApiRequest('POST', '/login', {
        cnicOrEmail: formData.cnicOrEmail,
        password: formData.password,
      });

      console.log(response);
      
      if (response?.success !== false) {
        if (!response.token) {
          setModalData((prevData) => ({
            ...prevData,
            modalOpen: true,
            verificationCode: "",
            verifying: false,
            modalDataMessage: response?.data?.message,
            cnicOrEmail: formData.cnicOrEmail
          }));
        }
      } else {
        setErrorMessage(response?.message);
        setTimeout(() => setErrorMessage(""), 2500);
      }
    } catch (error) {
      setErrorMessage("An error occurred during login, Try Again.");
      setTimeout(() => setErrorMessage(""), 2500);
    } finally {
      setFormData((prevData) => ({ ...prevData, loading: false }));
    }
  };

  const handleVerificationSubmit = async () => {
    setModalData((prevData) => ({ ...prevData, verifying: true }));
    try {
      const response = await ApiRequest('POST', '/verification', {
        cnicOrEmail: formData.cnicOrEmail,
        code: modalData.verificationCode,
      });

      if (response?.data?.success !== false) {
        const { user, token } = response.data;
        storeCookies("user",user);
        storeCookies("authToken",token,true);
        navigate("/sessions");
      } else {
        setModalData((prevData) => ({
          ...prevData,
          errorMessage: "Invalid Verification Code",
          verifying: false,
        }));
      }
    } catch (error) {
      setModalData((prevData) => ({
        ...prevData,
        errorMessage: "Invalid Verification Code",
        verifying: false,
      }));
    }
  };

  const handleModalChange = (e) => {
    const { name, value } = e.target;
    if(name === 'verificationCode')
      console.log(value);
    setModalData((prevData) => ({ ...prevData, [name]: value }));
  };

  return (
    <div className="login-main">
      <div className="login-left">
        <Lottie loop animationData={LoginImage} play style={{width: "600px" , height: "600px"}} />
      </div>
      <div className="login-right">
        <div className="login-right-container">
          <div className="login-logo">
            <img src="images/pace-gk-logo.png" alt="Logo" />
          </div>
          <div className="login-center">
            <h2>Welcome back!</h2>
            <p>Please enter your details</p>
            {errorMessage && (
              <div className="error-message">
                {errorMessage}
              </div>
            )}
            <form onSubmit={handleLogin}>
              <input
                type="text"
                placeholder="Enter Email or CNIC"
                name="cnicOrEmail"
                value={formData.cnicOrEmail}
                onChange={handleChange}
                required
              />
              <div className="pass-input-div">
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter Password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  required
                />
                {showPassword ? (
                  <FaEyeSlash onClick={() => setShowPassword(!showPassword)} />
                ) : (
                  <FaEye onClick={() => setShowPassword(!showPassword)} />
                )}
              </div>

              <div className="login-center-options">
                {/* <p> href="#" className="forgot-pass-link"> */}
                <p>
                  Forgot password?
                </p>
              </div>

              <div className="login-center-buttons">
                <button type="submit" disabled={formData.loading}>
                  {formData.loading ? "Logging in..." : "Log In"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <VerificationModal
        modalData={modalData}
        setModalData={setModalData}
        handleModalChange={handleModalChange}
        handleVerificationSubmit={handleVerificationSubmit}
      />
    </div>
  );
};

export default Login;
